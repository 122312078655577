<template>
    <ion-page>
        <ion-content class="page-timeline">
            <div class="page-timeline__container">
                <div
                    class="page-timeline__topbar d-flex justify-content-between align-items-center"
                >
                    <ion-button
                        fill="clear"
                        size="small"
                        @click="goTo('page-relatives-list')"
                    >
                        <ion-icon slot="icon-only" :icon="backIcon" />
                    </ion-button>
                    <div class="text-center">
                        {{ patientFirstName }} {{ patientLastName }}
                    </div>
                    <ion-button
                        fill="clear"
                        size="small"
                        @click="goTo('page-yield-patient')"
                    >
                        <ion-icon slot="icon-only" :icon="swapIcon" />
                    </ion-button>
                </div>
                <div
                    v-if="isLoading"
                    class="d-flex h-100 justify-content-center align-items-center"
                >
                    <ion-spinner />
                </div>
                <div v-else class="d-flex flex-column h-100">
                    <div class="d-flex mt-2 w-100 text-center buttons-group">
                        <ion-card
                            class="col top-action-card"
                            @click="goToQuestion"
                        >
                            <ion-card-content>
                                <div>
                                    <span
                                        class="card-icon"
                                        :class="[
                                            canAskQuestions
                                                ? 'color-yellow'
                                                : 'color-disabled',
                                        ]"
                                    >
                                        <IconPlus />
                                        <span class="card-icon-s">
                                            <IconQuestionCircle />
                                        </span>
                                    </span>
                                </div>

                                <span class="fs-13 text-muted">
                                    <span v-if="canAskQuestions">
                                        {{ $t("sendMessageToDoctor") }}
                                    </span>
                                    <span v-else>
                                        {{ $t("cantSendMessageToDoctor1") }}
                                    </span>
                                    <br />{{ $t("cantSendMessageToDoctor2") }}
                                    <strong class="text-black">{{
                                        $t("cantSendMessageToDoctor3")
                                    }}</strong>
                                </span>
                            </ion-card-content>
                        </ion-card>

                        <ion-card
                            class="col top-action-card"
                            @click="goToDedication"
                        >
                            <ion-card-content>
                                <div>
                                    <span
                                        class="card-icon"
                                        :class="[
                                            canSubmitMessage
                                                ? 'color-purple'
                                                : 'color-disabled',
                                        ]"
                                    >
                                        <IconPlus />
                                        <span class="card-icon-s stroke">
                                            <IconHeart />
                                        </span>
                                    </span>
                                </div>

                                <span class="fs-13 text-muted">
                                    <span v-if="canSubmitMessage">
                                        {{
                                            $t("sendMessageToPatient", {
                                                name: patientFirstName,
                                            })
                                        }}
                                        <br />{{ $t("sendMessageToPatient2") }}
                                    </span>
                                    <span v-else>
                                        {{
                                            $t("cantSendMessageToPatient", {
                                                name: patientFirstName,
                                            })
                                        }}
                                    </span>
                                </span>
                            </ion-card-content>
                        </ion-card>
                    </div>
                    <div v-if="hasCards" class="timeline">
                        <div
                            v-for="(cards, date) in groupedCards"
                            :key="`date-${date}`"
                        >
                            <span class="timeline__date">
                                <span> {{ date }} </span>
                            </span>
                            <TimelineCard
                                v-for="card in cards"
                                :key="`date-${date}-card-${card.id}`"
                                :card="card"
                                @card-hidden="fetchData()"
                            />
                        </div>
                    </div>
                    <div
                        v-else
                        class="mt-4 d-flex align-items-center justify-content-center flex-column color-gray h-100"
                    >
                        <IconEmpty class="w-25 mb-4" />
                        <span>{{ $t("nothingFound") }}</span>
                    </div>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script lang="ts" src="./Timeline.ts"></script>

<style lang="scss" src="./Timeline.scss"></style>
