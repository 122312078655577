<template>
    <ion-page>
        <ion-header>
            <ion-toolbar>
                <ion-buttons slot="primary">
                    <ion-button
                        class="button-close"
                        color="danger"
                        @click="handleCloseMedia()"
                    >
                        <IconTimes />
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content :fullscreen="true">
            <div class="d-flex align-items-stretch h-100">
                <ion-slides
                    v-if="isImage"
                    :zoom="true"
                    :options="sliderOptions"
                >
                    <ion-slide>
                        <div class="swiper-zoom-container">
                            <img
                                class="timeline-img"
                                :src="mediaUrl"
                                alt="media"
                            />
                        </div>
                    </ion-slide>
                </ion-slides>
                <!-- <ion-img :src="mediaUrl"></ion-img> -->
            </div>
        </ion-content>
    </ion-page>
</template>

<script lang="ts" src="./TimelineMediaModal.ts"></script>

<style lang="scss" src="./TimelineMediaModal.scss"></style>
