import { Vue, Options } from 'vue-class-component';
import TimelineMediaModal from '../TimelineMediaModal/TimelineMediaModal.vue';

import { IonImg, IonModal, IonSpinner } from '@ionic/vue';
import axios from '@/core/utilities/axios-instance-auth';
import { FileToken } from '@/../client-generator/generated-clients/api-clients';

@Options({
    name: 'TimelineCardMedia',
    props: {
        mediaUrl: {
            type: String,
        },
        mediaTokenUrl: {
            type: String,
        },
        mediaType: {
            type: String,
        },
    },
    components: {
        IonImg,
        IonModal,
        IonSpinner,
        TimelineMediaModal,
    },
})
export default class TimelineCardMedia extends Vue {
    mediaUrl!: string;
    mediaTokenUrl!: string;
    mediaType!: string;
    url: string | null = '';

    isLoading = true;
    showMediaModal = false;
    mediaLoadFailed = false;

    get isImage() {
        return this.mediaType.includes('image');
    }

    get isVideo() {
        return this.mediaType.includes('video');
    }

    get isAudio() {
        return this.mediaType.includes('audio');
    }

    mounted() {
        this.getMediaUrl();
    }

    async getMediaUrl() {
        try {
            if (this.isImage) {
                const response = await axios.get(this.mediaUrl, { responseType: 'arraybuffer' });
                this.url = 'data:image;base64,' + Buffer.from(response.data, 'binary').toString('base64');
            } else {
                const response = await axios.get(this.mediaTokenUrl, { responseType: 'json' });
                const fileToken = FileToken.fromJS(response.data);
                this.url = fileToken.url as string;
            }
        } catch (error) {
            this.mediaLoadFailed = true;
        } finally {
            this.isLoading = false;
        }
    }

    toggleShowMedia(doShow: boolean) {
        this.showMediaModal = doShow;
    }
}
