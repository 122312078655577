import { Vue, Options } from 'vue-class-component';

import { IonImg, IonHeader, IonToolbar, IonButtons, IonSlides, IonSlide } from '@ionic/vue';

import IconTimes from '@/views/components/icons/IconTimes.vue';

@Options({
    name: 'TimelineMediaModal',
    props: {
        mediaType: {
            required: true,
            type: String,
        },
        mediaUrl: {
            required: true,
            type: String,
        },
    },
    components: {
        IonHeader,
        IonImg,
        IonToolbar,
        IonButtons,
        IconTimes,
        IonSlides,
        IonSlide,
    },
    emits: ['close-media'],
})
export default class TimelineMediaModal extends Vue {
    mediaType!: string;

    sliderOptions = {
        allowTouchMove: false,
        zoom: {
            toggle: true,
            maxRatio: 3,
        },
    };

    get isImage() {
        return this.mediaType.includes('image');
    }

    handleCloseMedia() {
        this.$emit('close-media');
    }
}
